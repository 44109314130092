import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  getFreeTechs,
  getQueuesData,
  getScheduleData,
  updateColumnOrder,
  updateMatrixLog,
} from 'api/Matrix-log'
import { TimeOff } from 'api/TimeOff'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { getValueFromLS, setValueToLS } from 'hooks/useLocalStorage'
import moment from 'moment-timezone'
import { AppStateType } from 'store'
import { setTimeOffList } from '../../store/Orcatec/reducers/timeoff'
import { reorderTechs } from '../../store/Orcatec/reducers/matrix/helpers'
import {
  ActionType,
  AsyncThunkAPI,
  Column,
  DispatchEvent,
  DispatchSettings,
  EventColorType,
  MatrixLog,
  MatrixLogAPI,
  SliceState as DispatchSlice,
  ColumnFilters,
} from './types'
import {
  getAllEvents,
  getAllQueueEvents,
  getAllTechnicians,
  sortObjectsByDate,
  transformArrayToObj,
} from './helpers'
import { MatrixAddressType } from 'containers/MainContent/Orcatec/Settings/components/calendar/CalendarSettings'

import { queuesUpdated, selectQueueSlice } from './queueSlice'
import { filterQueueEvents } from './Queue/helpers'

const initialDate = moment().format('MM/DD/YYYY')

const initialData = {
  id: 0,
  date: initialDate,
  columns: {},
  time_start: 9,
  time_end: 18,
  events: {},
  technicians: [],
}

const initialState: DispatchSlice = {
  loading: false,
  currentDate: initialDate,
  data: {
    [initialDate]: initialData,
  },
  events: {},
  scrollDirection: 'up',
  technicians: [],
  queues: {},
  settings: {
    start_hour: 9,
    end_hour: 18,
    matrix_address_type: MatrixAddressType['ZIP code'],
    tooltip_mod: true,
    event_color_type: EventColorType.User,
    restrict_crud_source_type: false,
  },
  filters: {
    techName: '',
    showEmptyColumns: true,
  },
  appointments_created: [],
  freeTechs: [],
  error: null,
}

export const getMatrixLog = createAsyncThunk<
  void,
  { date: string },
  AsyncThunkAPI
>('matrixLog/getMatrixLog', async ({ date }, { dispatch, getState }) => {
  const matrixLog = getState().orcatec.dispatchSlice.data

  if (!matrixLog?.[date] && typeof date === 'string') {
    dispatch(addDay(date))
  }

  if (typeof date === 'string') {
    dispatch(getFreeTechnicians(date))
  }

  await dispatch(getMatrixSchedule(date))
})

export const getQueueEvents = createAsyncThunk(
  'dispatch/getQueueData',
  async (_, { dispatch }) => {
    const res = await getQueuesData()

    dispatch(queuesUpdated(res))

    return res
  },
)

const getMatrixSchedule = createAsyncThunk(
  'matrixLog/getSchedule',
  async (date: string, { dispatch, rejectWithValue }) => {
    const res = await getScheduleData(date).catch(error => {
      rejectWithValue(error?.response)

      return error
    })

    if (!!res?.response?.status) return rejectWithValue(res?.response)

    const data: MatrixLogAPI = res

    if (Array.isArray(data)) {
      let timeOff: TimeOff[] = []
      let events: DispatchEvent[] = []

      const mappedData = data.map(day => {
        timeOff = [...timeOff, ...day.timeOffs]
        events = [...events, ...getAllEvents(day)]

        return {
          id: day.id,
          time_start: day.time_start,
          time_end: day.time_end,
          date: moment(day.date).format('MM/DD/YYYY'),
          columns: transformArrayToObj(day.columns, 'column_template_id'),
          technicians: getAllTechnicians(day),
        }
      })

      dispatch(setTimeOffList(transformArrayToObj(timeOff)))

      return {
        events: transformArrayToObj(events),
        data: transformArrayToObj(mappedData, 'date'),
        meta: {
          technicians: getAllTechnicians(data[0]),
        },
      }
    }

    dispatch(setTimeOffList(transformArrayToObj(data.timeOffs)))

    return {
      data: {
        [date]: {
          id: data.id,
          date: data.date,
          time_start: data.time_start,
          time_end: data.time_end,
          columns: transformArrayToObj(
            data.columns.filter(column => !!column.column_template_id),
            'column_template_id',
          ),
          technicians: getAllTechnicians(data),
        },
      },
      events: transformArrayToObj(getAllEvents(data)),
      meta: {
        technicians: getAllTechnicians(data),
      },
    }
  },
)

export const updateColumnsOrder = createAsyncThunk<
  void,
  { columns: Column[]; source: number; target: number },
  AsyncThunkAPI
>(
  'matrixLog/updateColumnsOrder',
  async ({ columns, source, target }, { dispatch }) => {
    const sortedColumns = reorderTechs(columns, source, target)

    dispatch(columnsOrderUpdated(sortedColumns))

    try {
      await updateColumnOrder({ columns: sortedColumns })
    } catch (error) {
      console.error(error)
    }
  },
)

export const updateMatrixEvent = createAsyncThunk<
  void,
  ActionType,
  AsyncThunkAPI
>(
  'matrixLog/updateEvent',
  async ({ event: currentEvent, date }, { dispatch }) => {
    dispatch(setMatrixEvent([currentEvent]))

    try {
      return await updateMatrixLog(date, currentEvent)
    } catch (error) {
      let message = Object.values(error?.response?.data.message)

      if (error.response.data.errors.time) {
        message = error.response.data.errors.time
      }

      openNotificationWithIcon('error', { message })
      if (date) dispatch(getMatrixLog({ date }))

      return error
    }
  },
)

export const getFreeTechnicians = createAsyncThunk<void, string, AsyncThunkAPI>(
  'matrixLog/getFreeTechnicians',
  async (date, { dispatch }) => {
    try {
      const data = await getFreeTechs({ date })

      dispatch(freeTechsUpdated(data))
    } catch (error) {
      console.error(error)
    }
  },
)

const dispatchSlice = createSlice({
  name: 'matrixLog',
  initialState,
  reducers: {
    addDay: (state, { payload }: { payload: string }) => {
      state.data[payload] = initialData
    },
    createDispatchEvent: (
      state,
      { payload }: PayloadAction<{ currentEvent: DispatchEvent }>,
    ) => {
      state.appointments_created = [payload.currentEvent]
    },

    setMatrixEvent: (state, { payload }: PayloadAction<DispatchEvent[]>) => {
      payload.forEach(event => {
        state.events[event.id] = event
      })
    },
    eventDeleted: (state, { payload }) => {
      delete state.events[payload]
    },
    resetCreated: state => {
      state.appointments_created = []
    },
    scrollDirectionChanged: (state, { payload }) => {
      state.scrollDirection = payload
    },

    setCountOfLinks: (
      state,
      { payload }: PayloadAction<{ eventId: number; count: number }>,
    ) => {
      const { eventId, count } = payload

      if (!state.events[eventId]) return

      state.events[eventId].count_public_links = count
    },

    columnsOrderUpdated: (state, { payload }: PayloadAction<Column[]>) => {
      const newColumns = payload?.reduce<Record<string, Column>>((acc, val) => {
        acc[val.column_template_id] = val
        return acc
      }, {})
      state.data[state.currentDate].columns = newColumns
    },
    eventStatusUpdated: (
      state,
      { payload }: PayloadAction<{ eventId: number; status: number }>,
    ) => {
      const { eventId, status } = payload

      if (!state.events[eventId]) return

      state.events[eventId].status = status
    },
    eventClientNameUpdated: (
      state,
      { payload }: PayloadAction<{ eventId: number; client_name: string }>,
    ) => {
      const { eventId, client_name } = payload

      if (!state.events[eventId]) return

      state.events[eventId].client_name = client_name
    },

    dispatchSettingsUpdated: (
      state,
      { payload }: PayloadAction<DispatchSettings>,
    ) => {
      state.settings = payload
    },
    freeTechsUpdated: (state, action) => {
      state.freeTechs = action.payload
    },

    columnFilterUpdated: (
      state,
      action: PayloadAction<Partial<ColumnFilters>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }

      setValueToLS('matrixSettings', action.payload)
    },
  },
  extraReducers: builder => {
    builder.addCase(getMatrixSchedule.pending, (state, { meta }) => {
      if (typeof meta.arg === 'string') state.currentDate = meta.arg
      state.loading = true
      state.error = null
    })

    builder.addCase(getMatrixSchedule.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.events = !payload ? state?.events : []
    })

    builder.addCase(getMatrixSchedule.fulfilled, (state, { payload, meta }) => {
      state.data = {
        ...state.data,
        ...payload.data,
      }
      state.events = { ...state.events, ...payload.events }

      state.loading = false
      state.technicians = payload.meta.technicians

      if (typeof meta.arg === 'string') {
        const matrixSettings = getValueFromLS('matrixSettings')
        setValueToLS('matrixSettings', {
          ...matrixSettings,
          date: meta.arg,
        })
      }
    })

    builder.addCase(getQueueEvents.fulfilled, (state, { payload }) => {
      // state.queues = transformArrayToObj(payload)
      state.events = {
        ...state.events,
        ...transformArrayToObj(getAllQueueEvents(payload)),
      }
    })
  },
})

export default dispatchSlice.reducer
export const {
  addDay,
  columnsOrderUpdated,
  createDispatchEvent,
  dispatchSettingsUpdated,
  eventDeleted,
  eventStatusUpdated,
  freeTechsUpdated,
  resetCreated,
  setMatrixEvent,
  scrollDirectionChanged,
  setCountOfLinks,
  eventClientNameUpdated,
  columnFilterUpdated,
} = dispatchSlice.actions

//Selectors

export const selectMatrixLog = (state: AppStateType) =>
  state.orcatec.dispatchSlice
// const selectDispatchQueues = (state: AppStateType) => state.orcatec.dispatchSlice.queues
const selectAllEvents = (state: AppStateType) =>
  state.orcatec.dispatchSlice.events
// const selectCurrentDate = (state: AppStateType) => state.orcatec.dispatchSlice.currentDate

export const selectDispatchSettings = createSelector(
  selectMatrixLog,
  log => log.settings,
)
export const selectCurrentMatrixLog = (
  state: AppStateType,
  date?: string,
): MatrixLog => {
  const currentDate = date || state.orcatec.dispatchSlice.currentDate
  return state.orcatec.dispatchSlice.data[currentDate]
}

export const selectColumnFilters = createSelector(
  selectMatrixLog,
  log => log.filters,
)

export const selectColumnByDate = (date: string) =>
  createSelector([selectMatrixLog, selectColumnFilters], (log, filters) => {
    const columns = log.data?.[date]?.columns
    if (!columns) return

    const dateColumns = Object.values(columns)
    const { techName, showEmptyColumns } = filters

    const lowerCaseSearchTerm = techName?.toLowerCase()

    return dateColumns
      .filter(column => {
        const usersInfoMatches = column.technicians.some(tech => {
          return (
            tech.name.toLowerCase().includes(lowerCaseSearchTerm) ||
            tech.description.toLowerCase().includes(lowerCaseSearchTerm) ||
            tech.title.toLowerCase().includes(lowerCaseSearchTerm)
          )
        })

        return lowerCaseSearchTerm ? usersInfoMatches : column
      })
      .filter(column => (showEmptyColumns ? column : column.events?.length))
  })

export const selectEventsByDate = (date: string) =>
  createSelector(selectAllEvents, events =>
    Object.values(events).filter(
      event =>
        event.date === moment(date).format('MM/DD/YYYY') &&
        !!event.column_template_id,
    ),
  )

export const selectAllQueueEvents = createSelector(selectAllEvents, events =>
  Object.values(events).filter(event => !event.column_template_id),
)

// export const selectSortedEventsByQueue = (queueId: number) =>
//   createSelector(selectAllEvents, events =>
//     Object.values(events)
//       .filter(event => event.queue_id === queueId)
//       .sort((a, b) => +moment(b.updated_at).format('YYYYMMDDHHmm') - +moment(a.updated_at).format('YYYYMMDDHHmm')),
//   )

// const filterObject = {}

export const selectSortedEventsByQueue = (queueId: number) =>
  createSelector(
    [selectAllEvents, selectQueueSlice],
    (events, { entities: queues, filters }) =>
      sortObjectsByDate(
        filterQueueEvents(Object.values(events), filters).filter(
          event => event.queue_id === queueId,
        ),
        queues[queueId].sort_field,
        queues[queueId].sort_direction,
      ),
  )

export const selectColumnById = (id: number | null) =>
  createSelector(selectCurrentMatrixLog, currentMatrixLog => {
    if (!id) return null

    return currentMatrixLog.columns[id]
  })
